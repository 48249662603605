import _TableUncontrolled from "../Components/TableUncontrolled/TableUncontrolled";
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTableInstance = void 0;
var TableUncontrolled_1 = _TableUncontrolled;
var react_1 = _react;
var useTableInstance = function () {
  var context = (0, react_1.useContext)(TableUncontrolled_1.TableInstanceContext);
  if (!(context === null || context === void 0 ? void 0 : context.changeProps)) {
    // eslint-disable-next-line
    console.warn("useTableInstance warning: ka-table is not initialized, instanse is empty because: table is not rendered yet OR controlled mode is used");
  }
  return context;
};
exports.useTableInstance = useTableInstance;
export default exports;